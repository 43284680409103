import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  RunServiceRequest,
  useRunServiceMutation,
} from "../../../../api/endpoints/superApi";
import { Button, Alert, Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from "@mui/icons-material/Check";

import { setState } from "./talimDrSlice";
import DrConfigParams from "./drConfigParams";
import {
  JobItem,
  createJobItem,
} from "../../../../../components/Jobs/jobUtils";
import { writeJsonToS3 } from "../../../../../components/S3/S3Utils";

// --------------------------------------------------------------------------------------------------
interface ApiFileMetaData {
  path: string;
  data_type: string | null;
  drillhole_column_name: string | null;
  interval_from_column_name: string | null;
  interval_to_column_name: string | null;
  interval_depth_column_name: string | null;
  custom_metadata_column_names?: string[];
  features: string[];
}

// --------------------------------------------------------------------------------------------------
interface ApiRequest {
  project_name: string;
  environment: string;
  // job_item: JobItem;
  dataset_info: ApiFileMetaData;
  method: string;
  destination_dimensions: number;
  register_to_litholens: boolean;
  register_to_logsapi: boolean;
  descriptive_log_name: string;
  notification_email: string | null;
  structures_to_process: [] | null;
  drillholes_to_process: [] | null;
  s3_data_save_path: string | null;
  litholens_save_path_type: "s3" | "logsAPI" | null;
}

// --------------------------------------------------------------------------------------------------
interface DRRequestProps {
  bucketName: string;
  pathPrefix: string;
}

// --------------------------------------------------------------------------------------------------
const DRRequest: React.FC<DRRequestProps> = ({ bucketName, pathPrefix }) => {
  const talim_dr = useAppSelector((state) => state.talim.talimDrSlice);
  const dispatch = useAppDispatch();

  // --------------------------------------------------------------------------------------------------
  // Job Item & API Request

  const auth = useAppSelector((state) => state.auth);
  const projects = useAppSelector((state) => state.projects);

  // const url: string = window.location.href;
  // const company: string = url.split("/").filter(Boolean)[2];
  // const project: string = url.split("/").filter(Boolean)[3];
  // const projectName: string = company + "_" + project;
  const company = projects.selectedProject?.company || "company";
  const project = projects.selectedProject?.id || "project";
  const projectName =
    projects.selectedProject?.litholensName || "company_project";
  const type: string = "logs.dr";
  const createdBy = auth.user?.username || "superapi";

  const jobItem = createJobItem(company, project, type, createdBy);
  const [lastJobID, setLastJobID] = useState<string | null>(null);
  const env = process.env.REACT_APP_ENV || "test";

  // --------------------------------------------------------------------------------------------------
  const [apiRequest, setApiRequest] = useState<ApiRequest>({
    dataset_info: {} as ApiFileMetaData,
    // job_item: jobItem,
    project_name: projectName,
    environment: env,
    method: talim_dr.method,
    destination_dimensions: talim_dr.destination_dimensions,
    register_to_litholens: talim_dr.register_to_litholens,
    register_to_logsapi: talim_dr.register_to_logsapi,
    descriptive_log_name: talim_dr.descriptive_log_name,
    notification_email: talim_dr.notification_email,
    structures_to_process: talim_dr.structures_to_process,
    drillholes_to_process: talim_dr.drillholes_to_process,
    s3_data_save_path: talim_dr.s3_data_save_name,
    litholens_save_path_type: talim_dr.litholens_save_path_type,
  });

  // --------------------------------------------------------------------------------------------------
  // Send API Request

  const [runServiceMutation, runServiceMutationResult] =
    useRunServiceMutation();

  const handleSubmit = async (requestData: RunServiceRequest) => {
    runServiceMutation(requestData);
    const fixedItem = requestData.job_item as JobItem;
    setLastJobID(fixedItem.id);
    const pendingMessage = { status: "requested" };
    writeJsonToS3(
      bucketName,
      pathPrefix + "/responses/dr/" + fixedItem.id + ".json",
      pendingMessage
    );
  };

  const log = (type: any) => console.log.bind(console, type);

  // --------------------------------------------------------------------------------------------------

  const [open, setOpen] = useState(false);
  const handleAlertClose = (event: SyntheticEvent) => {
    setOpen(false);
  };

  useEffect(() => {
    if (talim_dr.method === "tsne" && talim_dr.destination_dimensions > 3) {
      dispatch(
        setState({
          key: "destination_dimensions",
          value: 3,
        })
      );
    }

    var newBaseStructuresInfo = {} as ApiFileMetaData;
    Object.values(talim_dr.filesInfo).forEach((element) => {
      newBaseStructuresInfo = {
        path: "s3://" + bucketName + "/" + element.fileKey,
        data_type: element.metaData.selectedType,
        drillhole_column_name: element.metaData.drillhole,
        interval_from_column_name: element.metaData.begin,
        interval_to_column_name: element.metaData.end,
        interval_depth_column_name: element.metaData.pointDepth,
        custom_metadata_column_names:
          element.metaData?.customMetaData || ([] as string[]),
        features: [],
      };
    });
    var modifiedLogDescripitveName = "new_log";
    if (typeof talim_dr.descriptive_log_name === "string") {
      modifiedLogDescripitveName = talim_dr.descriptive_log_name;
    }
    setApiRequest({
      ...apiRequest,
      dataset_info: newBaseStructuresInfo,
      register_to_litholens: talim_dr.register_to_litholens,
      destination_dimensions: talim_dr.destination_dimensions,
      method: talim_dr.method,
      register_to_logsapi: talim_dr.register_to_logsapi,
      notification_email: talim_dr.notification_email,
      descriptive_log_name: modifiedLogDescripitveName,
      s3_data_save_path: talim_dr.s3_data_save_name
        ? "s3://" +
          bucketName +
          "/" +
          pathPrefix +
          "/data/" +
          talim_dr.s3_data_save_name +
          ".csv"
        : null,
    });
  }, [talim_dr, projects.selectedProject]);

  useEffect(() => {
    // if (runServiceMutationResult?.isSuccess) {
    //   alert("API request has been sent successfully!");
    // } else {
    //   if (runServiceMutationResult?.isError) {
    //     alert("API request has been encountered an error!");
    //   }
    // }
    setOpen(true);
  }, [runServiceMutationResult]);

  // --------------------------------------------------------------------------------------------------
  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      <DrConfigParams />
      <Button
        variant="contained"
        onClick={(e) =>
          handleSubmit({
            service: "ll_run_talim_dr",
            request_body: apiRequest,
            job_item: jobItem,
            environment: env,
          } as RunServiceRequest)
        }
        disabled={runServiceMutationResult.isLoading}
        onError={log("errors")}
      >
        Send Request
      </Button>
      <Collapse in={open}>
        {(runServiceMutationResult?.isSuccess ||
          runServiceMutationResult?.isError) && (
          <Alert
            icon={<CheckIcon fontSize="inherit" />}
            severity={runServiceMutationResult?.isSuccess ? "success" : "error"}
            onClose={handleAlertClose}
          >
            {runServiceMutationResult?.isSuccess
              ? "Request sent successfully! Your request ID is " + lastJobID
              : "Request Sending has been ecnountered an error!"}
          </Alert>
        )}
      </Collapse>
      {/* <pre>{JSON.stringify(apiRequest, null, 2)}</pre> */}
    </div>
  );
};

export default DRRequest;
