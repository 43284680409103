import { createSlice, Dictionary, PayloadAction } from "@reduxjs/toolkit";
import { RootState, store } from "../../redux/store";
import { apiSlice } from "../api/apiSlice";

export interface decodedAccess {
  token_type: string;
  exp: number;
  iat: number;
  jti: string;
  user_id: number;
  first_name: string;
  last_name: string;
}

export interface user {
  username: string;
  firstName: string;
  lastName: string;
}

export interface CognitoTokens {
  AccessToken: string;
  ExpiresIn: number;
  IdToken: string;
  RefreshToken: string;
  TokenType: string;
}

export interface authState {
  cognitoTokens: CognitoTokens | null;
  user: user | null;
  isAuthenticated: boolean;
}

const cognitoTokens = localStorage.getItem("cognitoTokens");
const user = localStorage.getItem("user");

const initialState: authState = {
  cognitoTokens: cognitoTokens ? JSON.parse(cognitoTokens) : null,
  user: user ? JSON.parse(user) : null,
  isAuthenticated: cognitoTokens ? true : false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setAuthTokens: (
      state,
      action: PayloadAction<{ cognitoTokens: CognitoTokens }>
    ) => {
      localStorage.setItem(
        "cognitoTokens",
        JSON.stringify(action.payload.cognitoTokens)
      );
      state.cognitoTokens = action.payload.cognitoTokens;
      localStorage.setItem(
        "AWSCreds",
        JSON.stringify({
          "REACT_APP_AWS_ACCESS_KEY_ID": process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          "REACT_APP_AWS_SECRET_ACCESS_KEY": process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
          "REACT_APP_AWS_REGION": process.env.REACT_APP_AWS_REGION
        })
      );
    },

    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },

    setUser: (state, action: PayloadAction<{ user: user }>) => {
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      state.user = action.payload.user;
      state.isAuthenticated = true;
    },
    logout: (state) => {
      localStorage.removeItem("cognitoTokens");
      localStorage.removeItem("user");
      localStorage.removeItem("current_project");
      state.cognitoTokens = null;
      state.user = null;
      state.isAuthenticated = false;
      localStorage.removeItem("AWSCreds");
    },
  },
});

export const selectAuth = (state: RootState) => state.auth;

// Action creators are generated for each case reducer function
export const { setAuthTokens, setIsAuthenticated, setUser, logout } =
  authSlice.actions;

export default authSlice.reducer;
