import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  RunServiceRequest,
  useRunServiceMutation,
} from "../../../../api/endpoints/superApi";
import { Button, Alert, Collapse } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import TrainConfigParams from "./trainConfigParams";
import { setState } from "./talimTrainSlice";
import {
  JobItem,
  createJobItem,
} from "../../../../../components/Jobs/jobUtils";
import { writeJsonToS3 } from "../../../../../components/S3/S3Utils";

// --------------------------------------------------------------------------------------------------
interface ApiFileMetaData {
  path: string;
  data_type: string | null;
  drillhole_column_name: string | null;
  interval_from_column_name: string | null;
  interval_to_column_name: string | null;
  interval_depth_column_name: string | null;
  custom_metadata_column_names?: string[];
  dataset_source: "s3";
}

// --------------------------------------------------------------------------------------------------
interface ApiRequest {
  project_name: string;
  environment: string;
  // job_item: JobItem;
  dataset_info: ApiFileMetaData;
  task_type: string;
  training_features: string[] | null;
  target_feature: string;
  tuning_metric: string;
  mode: string;
  method: string;
  pca_components: number;
  train_size: number;
  imbalance_fix: boolean;
  normalize: boolean;
  // manual_threshold: number | null;
  explain_models: boolean;
  cross_validation: boolean;
  ensemble_method: string | null;
  number_of_ensembles: number;
  register_model_to_litholens: boolean;
  // register_to_logsapi: boolean;
  // descriptive_log_name: string;
  notification_email: string | null;
  // structures_to_process: [] | null;
  // drillholes_to_process: [] | null;
  s3_save_path: string | null;
}

// --------------------------------------------------------------------------------------------------
interface TrainRequestProps {
  bucketName: string;
  pathPrefix: string;
}

// --------------------------------------------------------------------------------------------------
const TrainRequest: React.FC<TrainRequestProps> = ({
  bucketName,
  pathPrefix,
}) => {
  const talim_train = useAppSelector((state) => state.talim.talimTrainSlice);
  const dispatch = useAppDispatch();

  const maxDimensions =
    talim_train.filesInfo[talim_train.selectedReferenceFile]?.tableData.columns
      .length - 3;

  // --------------------------------------------------------------------------------------------------
  // Job Item & API Request

  const auth = useAppSelector((state) => state.auth);
  const projects = useAppSelector((state) => state.projects);

  // const url: string = window.location.href;
  // const company: string = url.split("/").filter(Boolean)[2];
  // const project: string = url.split("/").filter(Boolean)[3];
  // const projectName: string = company + "_" + project;
  const company = projects.selectedProject?.company || "company";
  const project = projects.selectedProject?.id || "project";
  const projectName =
    projects.selectedProject?.litholensName || "company_project";
  const type: string = "logs.train";
  const createdBy = auth.user?.username || "superapi";

  const jobItem = createJobItem(company, project, type, createdBy);
  const [lastJobID, setLastJobID] = useState<string | null>(null);
  const env = process.env.REACT_APP_ENV || "test";

  // --------------------------------------------------------------------------------------------------
  const [apiRequest, setApiRequest] = useState<ApiRequest>({
    dataset_info: {} as ApiFileMetaData,
    // job_item: jobItem,
    project_name: projectName,
    environment: env,
    task_type: talim_train.task_type,
    training_features: talim_train.training_features,
    target_feature: talim_train.target_feature,
    tuning_metric: talim_train.tuning_metric,
    mode: talim_train.mode,
    method: talim_train.method,
    pca_components: talim_train.pca_components,
    train_size: talim_train.train_size,
    imbalance_fix: talim_train.imbalance_fix,
    normalize: talim_train.normalize,
    explain_models: talim_train.explain_models,
    cross_validation: talim_train.cross_validation,
    ensemble_method: talim_train.ensemble_method,
    number_of_ensembles: talim_train.number_of_ensembles,
    register_model_to_litholens: talim_train.register_model_to_litholens,
    // descriptive_log_name: "new_log",
    notification_email: talim_train.notification_email,
    // structures_to_process: null,
    // drillholes_to_process: null,
    s3_save_path: null,
    // register_to_litholens: talim_train.register_to_litholens,
    // register_to_logsapi: talim_train.register_to_logsapi,
    // descriptive_log_name: talim_train.descriptive_log_name,
    // notification_email: talim_train.notification_email,
    // structures_to_process: talim_train.structures_to_process,
    // drillholes_to_process: talim_train.drillholes_to_process,
    // s3_data_save_path: talim_train.s3_data_save_name,
    // litholens_save_path_type: talim_train.litholens_save_path_type,
  });

  // --------------------------------------------------------------------------------------------------
  // Send API Request
  const [runServiceMutation, runServiceMutationResult] =
    useRunServiceMutation();

  const handleSubmit = async (requestData: RunServiceRequest) => {
    console.log(JSON.stringify(requestData));
    runServiceMutation(requestData);
    const fixedItem = requestData.job_item as JobItem;
    setLastJobID(fixedItem.id);
    const pendingMessage = { status: "requested" };
    writeJsonToS3(
      bucketName,
      pathPrefix +
        "/responses/" +
        talim_train.task_type +
        "/" +
        fixedItem.id +
        ".json",
      pendingMessage
    );
  };

  const log = (type: any) => console.log.bind(console, type);

  // --------------------------------------------------------------------------------------------------
  const [open, setOpen] = useState(false);
  const handleAlertClose = (event: SyntheticEvent) => {
    setOpen(false);
  };

  useEffect(() => {
    var newBaseStructuresInfo = {} as ApiFileMetaData;
    Object.values(talim_train.filesInfo).forEach((element) => {
      newBaseStructuresInfo = {
        path: "s3://" + bucketName + "/" + element.fileKey,
        data_type: element.metaData.selectedType,
        drillhole_column_name: element.metaData.drillhole,
        interval_from_column_name: element.metaData.begin,
        interval_to_column_name: element.metaData.end,
        interval_depth_column_name: element.metaData.pointDepth,
        custom_metadata_column_names:
          element.metaData?.customMetaData || ([] as string[]),
        dataset_source: "s3",
      };
    });
    // var modifiedLogDescripitveName = "new_log";
    // if (typeof talim_train.descriptive_log_name === "string") {
    //   modifiedLogDescripitveName = talim_train.descriptive_log_name;
    // }
    setApiRequest({
      ...apiRequest,
      dataset_info: newBaseStructuresInfo,
      task_type: talim_train.task_type,
      training_features: talim_train.training_features,
      target_feature: talim_train.target_feature,
      tuning_metric: talim_train.tuning_metric,
      mode: talim_train.mode,
      method: talim_train.method,
      pca_components: talim_train.pca_components,
      train_size: talim_train.train_size,
      imbalance_fix: talim_train.imbalance_fix,
      normalize: talim_train.normalize,
      explain_models: talim_train.explain_models,
      cross_validation: talim_train.cross_validation,
      ensemble_method: talim_train.ensemble_method,
      number_of_ensembles: talim_train.number_of_ensembles,
      register_model_to_litholens: talim_train.register_model_to_litholens,
      // descriptive_log_name: "new_log",
      notification_email: talim_train.notification_email,
      s3_save_path: talim_train.s3_model_save_name
        ? "s3://" +
          bucketName +
          "/" +
          pathPrefix +
          "/models/" +
          talim_train.s3_model_save_name +
          ".json"
        : null,
      // structures_to_process: null,
      // drillholes_to_process: null,
      // register_to_litholens: talim_train.register_to_litholens,
    });
  }, [talim_train]);

  useEffect(() => {
    setOpen(true);
  }, [runServiceMutationResult]);

  // --------------------------------------------------------------------------------------------------
  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      <TrainConfigParams />
      <Button
        variant="contained"
        onClick={(e) =>
          handleSubmit({
            service: "ll_run_talim_" + talim_train.task_type,
            request_body: apiRequest,
            job_item: jobItem,
            environment: env,
            spot: false,
          } as RunServiceRequest)
        }
        onError={log("errors")}
        disabled={runServiceMutationResult.isLoading}
      >
        Send Request
      </Button>
      <Collapse in={open}>
        {(runServiceMutationResult?.isSuccess ||
          runServiceMutationResult?.isError) && (
          <Alert
            icon={<CheckIcon fontSize="inherit" />}
            severity={runServiceMutationResult?.isSuccess ? "success" : "error"}
            onClose={handleAlertClose}
          >
            {runServiceMutationResult?.isSuccess
              ? "Request sent successfully! Your request ID is " + lastJobID
              : "Request Sending has been ecnountered an error!"}
          </Alert>
        )}
      </Collapse>
      {/* <pre>{JSON.stringify(apiRequest, null, 2)}</pre> */}
    </div>
  );
};

export default TrainRequest;
